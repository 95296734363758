import { Paragraph } from "../../components/Paragraph";
import { HeaderSection } from "../../sections/HeaderSection";
import { useTranslation } from "react-i18next";


export const ErrorPage: React.FC = () => {

const { t } = useTranslation();
  const titles = ["error.title1"];
  return (
    <div className="min-h-[89vh]">
      <HeaderSection titles={titles}></HeaderSection>
      {/* wrapper for setting safe-area padding on mobile */}
      <div className="mt-16 lg:px-20 max-w-4xl ml-auto mr-auto">
        <div className="px-5 sm:px-6 lg:px-16 pt-4 pb-8 mt-8 md:mt-16 mx-8 lg:mx-8 mb-0 rounded-xl shadow-[rgba(14,_30,_37,_0.12)_0px_2px_4px_0px,_rgba(14,_30,_37,_0.32)_0px_2px_16px_0px] bg-[#f1f9ffb3]">
          <div className="flex gap-y-8 gap-x-16 justify-center flex-col lg:flex-row my-16">
            <div className="basis-auto shrink flex items-center">
              <Paragraph
                textAlign="left"
                text={t("error.text")}
              ></Paragraph>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

};

export default ErrorPage;