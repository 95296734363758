import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../components/Button/Button";
import { CurrencyGraph } from "../../components/CurrencyGraph";
import { FilterButton } from "../../components/FilterButton/FilterButton";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { Paragraph } from "../../components/Paragraph/Paragraph";
import { DashboardControls } from "../../sections/DashboardControls";
import { HeaderSection } from "../../sections/HeaderSection";
import { getEthereumConsumptions, getEthereumEmissions } from "../../services/currency-backend-service";
import { RootState, actionCreators } from "../../state";
import { CoinConsumptionData, CoinEmissionData, CoinGraphData } from "../../typings/CurrencyTypes";
import { oneYearAgo, today } from "../GeneralDashboard/dashboardConstants";
import { fitConsumptionGraphDataToDates, fitEmissionGraphDataToDates, getDatesBetween, performAutoScroll } from "../GeneralDashboard/dashboardHelpers";
import ethReportCover from "../../assets/CCRI-eth-report-cover.png";
import ethReportComparison from "../../assets/CCRI-eth-report-comparison.png";
import { bindActionCreators } from "redux";




export const EthereumMerge: React.FC = () => {

    const { t } = useTranslation();
    const colorPalette = new Map([["eth", "#abb1b5"], ["eth2", "#0877BC"]]);

    const scrollRef = useRef<null | HTMLDivElement>(null)

    const currencies = useSelector((state: RootState) => state.coins.currencies);

    const ethAssets = currencies.filter(asset => (asset.ticker === "eth" || asset.ticker === "eth2"));

    const startDate = useSelector(
        (state: RootState) => state.dashboard.startDate
    );
    const endDate = useSelector((state: RootState) => state.dashboard.endDate);

    const graphDataType = useSelector(
        (state: RootState) => state.dashboard.graphDataType
    );

    const dispatch = useDispatch();
    const {
        setStartDate,
    } = bindActionCreators(actionCreators, dispatch);

    const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);

    // Dashboard-iternal state
    const [graphIsLoading, setGraphIsLoading] = useState(true);

    // All GraphDataPoints
    const [allConsumptionGraphData, setAllConsumptionGraphData] = useState<
        CoinConsumptionData[] | undefined
    >();
    const [allEmissionGraphData, setAllEmissionGraphData] = useState<
        CoinEmissionData[] | undefined
    >();
    // GraphDataPoints filtered with start and end
    const [periodConsumptionGraphData, setPeriodConsumptionGraphData] = useState<
        CoinConsumptionData[] | undefined
    >();
    const [periodEmissionGraphData, setPeriodEmissionGraphData] = useState<
        CoinEmissionData[] | undefined
    >();
    // GraphDataPoints that are currently shown in graph
    const [filteredConsumptionGraphData, setFilteredConsumptionGraphData] =
        useState<CoinGraphData | undefined>();
    const [filteredEmissionGraphData, setFilteredEmissionGraphData] = useState<
        CoinGraphData | undefined
    >();

    const [graphLabels, setGraphLabels] = useState<string[]>(
        getDatesBetween(startDate, endDate)
    );

    const [width, getWidth] = useState(window.innerWidth);

    const setWidth = () => {
        getWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", setWidth);

        return () => {
            window.removeEventListener("resize", setWidth);
        };
    }, []);

    useEffect(() => {
        performAutoScroll(width, scrollRef)
    }, []);


    // Fetch graphdata for consumption and emission
    useEffect(() => {

        const fetchGraphData = async () => {
            if (currencies && currencies.length) {

                const emissionData = await getEthereumEmissions(currencies);
                const consumptionData = await getEthereumConsumptions();

                const adjustConsumptionDataForMergePage = (asset: CoinConsumptionData) => {
                    if (asset.ticker === "eth2") {
                        (asset as CoinConsumptionData).yearlyConsumption = (asset as CoinConsumptionData).yearlyConsumption.map(value => (value ? value / 1000000000 : null));
                    }
                }
                const adjustEmissionDataForMergePage = (asset: CoinEmissionData) => {
                    if (asset.ticker === "eth2") {
                        (asset as CoinEmissionData).yearlyEmissions = (asset as CoinEmissionData).yearlyEmissions.map(value => (value ? value / 1000000000 : null));
                    }
                }


                consumptionData.forEach((elem) => {
                    adjustConsumptionDataForMergePage(elem);
                })
                emissionData.forEach((elem) => {
                    adjustEmissionDataForMergePage(elem);
                })

                setAllEmissionGraphData(emissionData);
                setAllConsumptionGraphData(consumptionData);

                // set timeframe for shown data initially
                const periodConsumption = fitConsumptionGraphDataToDates(
                    consumptionData,
                    today.toISOString(),
                    oneYearAgo.toISOString()
                );
                const periodEmission = fitEmissionGraphDataToDates(
                    emissionData,
                    today.toISOString(),
                    oneYearAgo.toISOString()
                );

                setPeriodConsumptionGraphData(periodConsumption);
                setPeriodEmissionGraphData(periodEmission);
                setGraphIsLoading(false);
            }

        };
        fetchGraphData();
    }, [currencies]);

    // Generate new datapoints for graph if startdate, enddate or currencies changed
    useEffect(() => {
        if (graphIsLoading === false) {
            setGraphLabels(getDatesBetween(startDate, endDate));

            const adjustedConsumptionData = fitConsumptionGraphDataToDates(
                allConsumptionGraphData,
                startDate.toISOString(),
                endDate.toISOString()
            );

            const adjustedEmissionData = fitEmissionGraphDataToDates(
                allEmissionGraphData,
                startDate.toISOString(),
                endDate.toISOString()
            );

            setPeriodConsumptionGraphData(adjustedConsumptionData);
            setPeriodEmissionGraphData(adjustedEmissionData);
        }
    }, [
        startDate,
        endDate,
        graphIsLoading,
        allConsumptionGraphData,
        allEmissionGraphData,
    ]);

    
    // set merge page period on mount
    useEffect(() => {
        setStartDate(new Date(2022, 0, 1));
    }, [])


    // reload graph with fitting data if current assets or datapoints changed
    useEffect(() => {
        let zeros = new Array(graphLabels.length);
        for (let i = 0; i < graphLabels.length; ++i) zeros[i] = null;
        let consumptionDatasets = ethAssets.map((asset) => {
            return {
                label: asset.ticker === "eth2" ? "Ethereum PoS" : "Ethereum PoW",
                data:
                    periodConsumptionGraphData?.find(
                        (entry) => entry.ticker === asset.ticker
                    )?.yearlyConsumption || zeros,
                backgroundColor: colorPalette.get(asset.ticker)!,
                borderColor: colorPalette.get(asset.ticker)!,
                pointRadius: 0,
                borderWidth: width < 600 ? 1.5 : 2,
            };
        });

        let emissionDatasets = ethAssets.map((asset) => {
            return {
                label: asset.ticker === "eth2" ? "Ethereum PoS" : "Ethereum PoW",
                data:
                    periodEmissionGraphData?.find(
                        (entry) => entry.ticker === asset.ticker
                    )?.yearlyEmissions || zeros,
                backgroundColor: colorPalette.get(asset.ticker)!,
                borderColor: colorPalette.get(asset.ticker)!,
                pointRadius: 0,
            };
        });

        setFilteredConsumptionGraphData({
            labels: graphLabels,
            datasets: consumptionDatasets,
        });
        setFilteredEmissionGraphData({
            labels: graphLabels,
            datasets: emissionDatasets,
        });
    }, [
        graphLabels,
        periodConsumptionGraphData,
        width,
        periodEmissionGraphData,
    ]);

    const toggleFilterMenu = () => {
        setMobileFiltersOpen(!mobileFiltersOpen);
    };

    return (
        <div>
            <DashboardControls
                popup={true}
                popupOpen={mobileFiltersOpen}
                isEthereumMergePage={true}
                closeMobile={toggleFilterMenu}
            ></DashboardControls>
            <div
                className={`min-h-screen ${mobileFiltersOpen ? "hidden" : "relative"}`}
            >
                {/* Header */}
                <div className="-mt-3 md:hidden fixed z-[60] left-2/4 -translate-x-2/4">
                    <FilterButton text="Filter" onClick={toggleFilterMenu}></FilterButton>
                </div>
                <div className="block">
                    <HeaderSection
                        titles={[t("ethereum-merge.title1")]}
                        subtitle={t("ethereum-merge.heading")}
                    ></HeaderSection>
                </div>
                {/* <div className="-mt-20 md:hidden fixed z-[60] left-2/4 -translate-x-2/4">
                    <FilterButton text="Filter" onClick={toggleFilterMenu}></FilterButton>
                </div> */}
                {/* wrapper for setting safe-area padding on mobile */}
                <div
                    style={{
                        paddingLeft: "env(safe-area-inset-left)",
                        paddingRight: "env(safe-area-inset-right)",
                    }}
                >
                    <div className="pt-8 lg:px-20 max-w-screen-2xl ml-auto mr-auto" ref={scrollRef}>
                        <div className="px-4 sm:px-6 lg:px-8 md:mt-20 mt-8 mb-0">
                            <DashboardControls
                                popupOpen={false}
                                popup={false}
                                isEthereumMergePage={true}
                            ></DashboardControls>
                            <div className="h-7 mt-2">
                                <LoadingSpinner loading={graphIsLoading}></LoadingSpinner>
                            </div>
                            <div>
                                {filteredConsumptionGraphData && filteredEmissionGraphData ? (
                                    <CurrencyGraph
                                        isMergePage={true}
                                        currentAssetType={"pow"}
                                        graphDataType={graphDataType}
                                        data={
                                            graphDataType === "consumption"
                                                ? filteredConsumptionGraphData
                                                : filteredEmissionGraphData
                                        }
                                    ></CurrencyGraph>
                                ) : (
                                    <p>No data available</p>
                                )}
                            </div>
                        </div>
                        <div className="px-5 sm:px-6 lg:px-8 mt-8 md:mt-16 mx-8 lg:mx-8 mb-0 pb-4 rounded-xl shadow-[rgba(14,_30,_37,_0.12)_0px_2px_4px_0px,_rgba(14,_30,_37,_0.32)_0px_2px_16px_0px] bg-[#f1f9ffb3]">
                            <div className="mb-4 flex gap-10 justify-center flex-col lg:flex-row">
                                <div className="basis-2/8 shrink-0 h-64 flex justify-center">
                                    <img
                                        className="h-64 lg:h-full object-scale-down"
                                        src={ethReportCover}
                                        alt="CCRI Ethereum Report Cover">
                                    </img>
                                </div>
                                <div className="basis-auto shrink flex items-center">
                                    <Paragraph
                                        textAlign="left"
                                        text={t("ethereum-merge.text1")}
                                    ></Paragraph>
                                </div>
                            </div>
                            <div className="flex justify-center lg:justify-end">
                                <Button
                                    text={t("ethereum-merge.button1")}
                                    onClick={() =>
                                        window.open("https://carbon-ratings.com/dl/eth-report-2022", "_blank")
                                    }
                                    type="cta"
                                ></Button>
                            </div>
                        </div>
                        <div className="px-4 sm:px-6 lg:px-8 md:mt-16 my-8 flex flex-col justify-center items-center">
                            <div className="md:w-9/12">
                                <img
                                    className="object-scale-down"
                                    src={ethReportComparison}
                                    alt="CCRI Ethereum Report Comparison">
                                </img>
                            </div>
                            <div className="md:w-1/2 w-5/6">
                                <Paragraph
                                    imgCaption={true}
                                    textAlign="center"
                                    text={t("ethereum-merge.text2")}
                                ></Paragraph>
                            </div>
                        </div>
                        <div className="fixed bottom-0 right-0  mr-5 mt-5 mb-5 md:hidden">
                            <FilterButton onClick={toggleFilterMenu}></FilterButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EthereumMerge;