import React from "react";
import { Navigate } from "react-router-dom";
import { ErrorPage } from "./pages/ErrorPage";


interface ErrorBoundaryProps {
    children: React.ReactNode;
}

interface ErrorBoundaryState {
    hasError: boolean;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    // This method is called when an error occurs and updates the state BEFORE rendering.
    static getDerivedStateFromError(error: Error): ErrorBoundaryState {
        console.error("Error caught in getDerivedStateFromError:", error);
        return { hasError: true };
    }

    render() {
        if (this.state.hasError) {
            return <div>
                <ErrorPage />
            </div>
        }
        return this.props.children; //render the children if there is no errors
    }
}

export default ErrorBoundary;


